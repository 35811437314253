import React, { Component } from "react";
import Slider from "react-slick";
import data from "../../yourdata";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const listItems = data.photos.map((photo) =>
            <img key={photo.id} src={photo.imageURL} alt={photo.blurb} />
        );
        return (
            <Slider {...settings} className="slider">
                {listItems}
            </Slider>
        );
    }
}