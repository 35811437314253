import React, { Component } from "react";
import Slider from "react-slick";
import data from "../../yourdata";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class MainSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            cssEase: "linear"
        };
        const listItems = data.mainPhotos.map((photo) =>
            <img key={photo.id} src={photo.imageURL} alt={photo.blurb} />
        );
        return (
            <Slider {...settings} className="slider">
                {listItems}
            </Slider>
        );
    }
}