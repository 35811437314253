import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import Header from "../components/header"
import Photos from "../components/photos"
import Facilities from "../components/facilities";
import About from "../components/about";
import Pricing from "../components/pricing";
import Team from "../components/team";
import Included from "../components/included";
import Contact from "../components/contact";
import Inspiration from "../components/inspiration";

const IndexPage = () => (
  <Layout>
    <SEO title="Menagerie Venue" />
      <Header />
      <hr className="section-break-1"/>
      <About />
    <hr className="section-break-1"/>
      <Photos />
    <hr className="section-break-1"/>
      {/*<Facilities />*/}
    {/*<hr className="section-break-1"/>*/}
      <Included />
    <hr className="section-break-1"/>
    <Inspiration />
      <hr className="section-break-1"/>
      {/*<Pricing />*/}
    {/*<hr className="section-break-1"/>*/}
      <Team />
    <hr className="section-break-1"/>
      <Contact />
      <hr className="section-break-1"/>
  </Layout>
)

export default IndexPage
