import React from "react"
import data from "../yourdata";
import Fade from "react-reveal/Fade";

const Included = () => {
    const paragraphs = data.includedText.map((para) =>
        <p key={para}>{para}</p>
    );
    return (
        <div className="section" id="whatsincluded">
            <Fade bottom>
                <div className="section-name left">
                    <h1>What's Included</h1>
                    <p>{paragraphs}</p>
                </div>
            </Fade>
        </div>
    )
}

export default Included
