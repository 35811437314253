import React from "react"
import data from "../yourdata";
import Fade from "react-reveal/Fade";

const About = () => {
  const paragraphs = data.aboutText.map((para) =>
      <p key={para}>{para}</p>
  );
  return (
      <div className="section" id="about">
        <Fade bottom>
          <div className="section-name left">
            <h1>About</h1>
            <p>{paragraphs}</p>
          </div>
        </Fade>
      </div>
  )
}

export default About
