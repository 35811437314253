import React from "react"
import MainSlider from "./subcomponents/mainslider";

const Header = () => {
  return (
    <div className="header-section" id="home">
        <div className="photos">
            <MainSlider></MainSlider>
        </div>
        <h1>The Menagerie</h1>
        <h2>Historic Wedding Venue and Country Retreat</h2>

      {/*<div className="container">*/}
      {/*  <div className="header-wrapper">*/}
      {/*    <Fade bottom>*/}
      {/*      <div className="image-wrapper">*/}
      {/*        <img src={data.titleImage} alt="about"></img>*/}
      {/*      </div>*/}
      {/*    </Fade>*/}
      {/*    <Fade bottom cascade>*/}
      {/*      <div className="heading-wrapper">*/}
      {/*        <h1>*/}
      {/*          {data.headerTagline[0]*/}
      {/*            ? data.headerTagline[0]*/}
      {/*            : "The Menagerie"}*/}
      {/*        </h1>*/}
      {/*        <h1>*/}
      {/*          {" "}*/}
      {/*          {data.headerTagline[1]*/}
      {/*            ? data.headerTagline[1]*/}
      {/*            : "Wedding Venue"}*/}
      {/*        </h1>*/}
      {/*        <h1>*/}
      {/*          {" "}*/}
      {/*          {data.headerTagline[2]*/}
      {/*            ? data.headerTagline[2]*/}
      {/*            : "Country Retreat"}*/}
      {/*        </h1>*/}
      {/*      </div>*/}
      {/*    </Fade>*/}
      {/*    <Fade bottom>*/}
      {/*      <p>{data.headerParagraph}</p>*/}
      {/*    </Fade>*/}
      {/*    <Fade bottom>*/}
      {/*      <a*/}
      {/*        href={`mailto:${*/}
      {/*          data.contactEmail ? data.contactEmail : "enquiries@menagerievenue.co.uk"*/}
      {/*        }`}*/}
      {/*        className="primary-btn"*/}
      {/*      >*/}
      {/*        GET IN TOUCH*/}
      {/*      </a>*/}
      {/*    </Fade>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default Header
