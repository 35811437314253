import React from "react"

export class TeamMember extends React.Component {
    render() {
        return (
            <div className="team-member">
                <img src={this.props.imgURL} alt={this.props.name}></img>
                <h2>{this.props.name}</h2>
                <p>{this.props.title}</p>
            </div>
        );
    }
}