import React from "react"
import data from "../yourdata";
import Fade from "react-reveal/Fade";

const Inspiration = () => {
    const paragraphs = data.inspirationText.map((para) =>
        <p key={para}>{para}</p>
    );
    return (
        <div className="section" id="inspiration">
            <Fade bottom>
                <div className="section-name right">
                    <h1>Inspiration</h1>
                    <p>{paragraphs}</p>
                </div>
            </Fade>
        </div>
    )
}

export default Inspiration
