import React from "react"
import data from "../yourdata";
import Fade from "react-reveal/Fade";
import {TeamMember} from "./subcomponents/teammember";

const Team = () => {
    const team = data.team.map((member) =>
        <TeamMember name={member.name} title={member.title} imgURL={member.imgURL}/>
    );
    return (
        <div className="section" id="team">
            <div className="section-name center">
                <h1>Team</h1>
                <div>{team}</div>
            </div>
        </div>
    )
}

export default Team
