import React from "react"
import SimpleSlider from "./subcomponents/simpleslider"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Photos = () => {
    return (
        <div className="section" id="photos">
            <div className="section-name right">
                <h1>Photos</h1>
                <p></p>
                <div className="photos-section">
                    <SimpleSlider />
                </div>
            </div>
        </div>
    )
}

export default Photos
