import React from "react"
import data from "../yourdata";
import Fade from "react-reveal/Fade";

const Contact = () => {
    return (
        <div className="section" id="contact">
            <Fade bottom>
                <div className="section-name center">
                    <h1>Contact</h1>
                    <p>Get in touch with us for more info:</p>
                    <a className="email-link" href={`mailto:${data.contactEmail}`}>
                        {data.contactEmail}
                    </a>
                </div>
            </Fade>
        </div>
    )
}

export default Contact
